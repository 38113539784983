import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

const style = {
    text: tw`antialiased text-xs leading-normal font-normal  mb-0`,
    marginText: tw`ml-6 mr-6`,
    littleTitle: tw`antialiased text-tellow-purple text-base font-medium tracking-tight mb-2`,
    colContent: tw`flex flex-col items-center mb-2 text-center`,
    rowContent: tw`flex flex-row items-center `,
    altSvg: tw`h-14 w-14 text-transparent  mb-3 mr-4 rounded-full bg-tellow-purple bg-opacity-10 flex justify-center items-center p-2`,
}

const FeatureSection = styled.div`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1.5rem;
    margin: 40px 0;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 1024px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
`
const FeatureIcons = styled.img`
    margin-bottom: 0px;
    height: 34px;
`

function BankingFeatures({ data }) {
    return (
        <FeatureSection>
            {data.map((item) => (
                <>
                    <div css={style.colContent}>
                        <div css={style.altSvg}>
                            <FeatureIcons src={item.Icon} alt="" />
                        </div>
                        <p css={style.littleTitle}>{item.title}</p>
                        <p css={[style.text, style.marginText]}>{item.text}</p>
                    </div>
                </>
            ))}
        </FeatureSection>
    )
}

export default BankingFeatures
