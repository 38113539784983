import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import EmailInput from './banking-email-input'
import CheckMark from '../../../images/banking/TickIcon.png'

const style = {
    container: tw`flex relative mx-auto max-w-component-container z-20 mb-0`,
    img: tw`md:inline mx-auto  mb-0 p-0`,
    wrapper: tw`max-w-7xl mb-0 mx-auto px-0 md:px-6 lg:px-8 `,
    imageContainer: tw`flex flex-col justify-center`,
    inputFiledStyle: tw`rounded-sm`,
    textPurple: tw`text-tellow-purple`,
    bigTitle: tw`antialiased leading-tight tracking-tighter text-4xl font-bold items-start `,
    text: tw`antialiased text-xs max-w-prose font-normal mb-4 opacity-80`,
    mainText: tw`antialiased text-sm max-w-prose font-normal mb-3 opacity-80`,
    content: tw`flex flex-col h-auto  lg:pt-10 px-4 md:px-0`,
}

const DisplayGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2.5rem;
    place-items: center;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 0.2rem;
    }
`
const MainTitle = styled.h1`
    font-size: 2.74rem;
    margin-bottom: 1rem;
    font-weight: bold;
    @media (max-width: 768px) {
        font-size: 40px;
        font-weight: bold;
    }
`
const CheckMarkImg = styled.img`
    height: auto;
    width: 10px;
    margin-right: 8px;
`
const TitleText = styled.h1`
    font-size: 36px;
    margin-bottom: 1.5rem;
    font-weight: bold;
    opacity: 50%;
    @media (max-width: 768px) {
        font-weight: bold;
        font-size: 30px;
        justify-content: center;
        text-align: center;
        opacity: 50%;
        margin-bottom: 4px;
    }
`
const Paragraph = styled.p`
    @media (max-width: 768px) {
        text-align: center;
        justify-content: center;
    }
`
const TextContainer = styled.div`
    @media (max-width: 768px) {
        align-items: center;
        justify-content: center;
    }
`

const HideElement = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`
const ShowElement = styled.div`
    @media (min-width: 768px) {
        display: none;
    }
`
const MobileHeader = styled.div`
    justify-content: center;
    text-align: center;
    align-items: center;
`
const DesktopHeader = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: -25px;
`

const HeaderList = styled.ul`
    margin-bottom: 1.8rem;
`
const AnimationContainer = styled.div`
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

// Renders desktop and mobile header

const BankingHeader = ({ data }) => (
    <div css={style.container}>
        <div css={style.wrapper}>
            <DisplayGrid>
                <TextContainer css={style.content}>
                    {/* desktop header */}
                    <HideElement>
                        <DesktopHeader>
                            <TitleText css={(style.bigTitle, style.textPurple)}>{data.titleText}</TitleText>
                        </DesktopHeader>
                        <MainTitle css={(style.bigTitle, style.textPurple)}>{data.title}</MainTitle>
                    </HideElement>

                    <Paragraph css={style.mainText}>{data.text}</Paragraph>
                    <HeaderList css={style.text}>
                        {data.list.map((item) => (
                            <>
                                <li key={item}>
                                    <CheckMarkImg src={CheckMark} alt="#" />
                                    {item}
                                </li>
                            </>
                        ))}
                    </HeaderList>

                    <EmailInput />
                </TextContainer>

                {/* mobile header */}
                <div>
                    <ShowElement>
                        <MobileHeader>
                            <div>
                                <TitleText css={(style.bigTitle, style.textPurple)}>{data.titleText}</TitleText>
                            </div>
                            <MainTitle css={(style.bigTitle, style.textPurple)}>{data.title}</MainTitle>
                        </MobileHeader>
                    </ShowElement>

                    <div css={(style.imageContainer, style.content)}>
                        <AnimationContainer>{data.animation}</AnimationContainer>
                    </div>
                </div>
            </DisplayGrid>
        </div>
    </div>
)

export default BankingHeader
