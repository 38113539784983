import React from 'react'
import styled from 'styled-components'

import { Button } from '../../UI'

const SignupButton = styled(Button)`
    height: 58px;
    width: 235px;
    align-items: center;
    justify-content: center;
`
// Renders signup button

const BankingEmailInput = () => (
    <SignupButton href="https://app.tellow.nl/registreer?origin=TELLOWBANKING" target="_blank" rel="noopener noreferrer">
        Open free payment account <span className="arrow">{'->'}</span>
    </SignupButton>
)

export default BankingEmailInput
