import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer } from '../../components/shared/landingPages/layoutComponents'
import BankingHeader from '../../components/pages/banking/banking-header'
import BankingFeatures from '../../components/pages/banking/banking-features'
import BankingAccountancy from '../../components/pages/banking/banking-accountancy'
import TellowLogo from '../../images/banking/Frame.png'
import DocumentIcon from '../../images/banking/document.png'
import contactlessIconIcon from '../../images/banking/contactlessIcon.png'
import ShieldLockIcon from '../../images/banking/shieldLock.png'
import EarthIcon from '../../images/banking/earth.png'
import CreditCardIcon from '../../images/banking/creditCard.png'
import LottieBankingAnimation from '../../images/lottieAnimations/BankingAnimation.js'
import { BANKING_CAMPAIGN_ID } from './zakelijke-rekening-openen.js'

const bankingHeaderData = {
    titleText: `Nieuw voor zzp’ers`,
    title: ` Tellow Bankieren`,
    titleImage: TellowLogo,
    text: `Onze alles-in-een oplossing die meer doet dan je bank, allemaal vanuit één app.`,
    list: [`IBAN en Mastercard-betaalkaart`, `Facturen en offertes maken`, `Bonnen en administratie bijhouden`, `Belasting indienen en betalen`],
    fadedText: `In 2022 krijgen alle zzp'er de zakelijke bankrekening gratis, voor altijd!`,
    animation: <LottieBankingAnimation />,
}

const featuresData = [
    {
        Icon: CreditCardIcon,
        title: `Alles wat je nodig hebt`,
        text: `Eigen IBAN, realtime transfers naar alle Europese IBANs en ondersteuning SEPA-incasso’s`,
    },
    {
        Icon: DocumentIcon,
        title: `Automatisch boekhouden`,
        text: `Makkelijker boekhouden plus belastingaangiftes direct indienen én betalen`,
    },
    {
        Icon: EarthIcon,
        title: `Overal betalen`,
        text: `Met Mastercard, ApplePay en GooglePay hebben we sterke dekking in heel Nederland`,
    },
    {
        Icon: ShieldLockIcon,
        title: `Veilig en zekerheid`,
        text: `Verzekerd van je geld met 100% garantie op je saldo, ook boven de 100.000 euro`,
    },
]

const bankingAccountancyData = {
    title: `Één app voor boekhouding`,
    text: `Wij geloven in een one-stop-shop voor ondernemers, waar we alle financiële zaken voor ondernemers regelen vanuit een plek. Administratie, boekhouden, bankieren, belastingen, financiering en meer, van het starten van je onderneming tot het beëindigen. Wij maken het makkelijker en dat scheelt jou kostbare tijd!`,
    points: [
        'Gratis Nederlandse bankrekening',
        'Automatisch boekhouden',
        'Overal makkelijk bankieren',
        'Makkelijk factureren',
        'Bonnen scannen en digitaal bewaren',
        'Uitgaven automatisch verwerken',
        'En nog veel meer',
    ],
    cardNumber: `1234 5678 9012 3456`,
    cardName: `CARD HOLDER`,
    icon: contactlessIconIcon,
    campaign: BANKING_CAMPAIGN_ID,
}

const ComingSoon = () => (
    <Layout registrationButtonCampaignId={BANKING_CAMPAIGN_ID}>
        <Meta
            keywords={['boekhouder', 'zzp', 'freelancer', 'ondernemer', 'eenmanszaak', 'administratie', 'boekhouden', 'boekhoudpakket']}
            path="/en/functies/zakelijke-rekening-openen"
            title="Bankieren voor ondernemers | Tellow"
            description="Meta description: Bankieren voor ondernemers. Met Tellow bankieren krijgen ondernemers en zzp’ers toegang tot één app waarin zij all hun financien kunnen regelen."
        />

        {/* header components */}
        <RelativeContainer>
            <BankingHeader data={bankingHeaderData} />
        </RelativeContainer>

        {/* banking features component */}
        <RelativeContainer>
            <ComponentContainer>
                <BankingFeatures data={featuresData} />
            </ComponentContainer>
        </RelativeContainer>

        {/* banking accountancy componenet */}
        {/* text transition library is used for animation */}
        <RelativeContainer>
            <ComponentContainer>
                <BankingAccountancy data={bankingAccountancyData} />
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default ComingSoon
